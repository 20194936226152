/* eslint no-console:0 */

define(["jquery", "underscore"], function ($, _) {
  var levels = {
    DEBUG: "DEBUG",
    ERROR: "ERROR",
    INFO: "INFO",
    WARN: "WARN"
  };

  var debugEnabled = false;

  /*
   * Log a message to the browser's console.
   *
   * All arguments (other than an optional level) are logged.
   *
   * @param String    level one of the log level constants.
   *                     The level (in supporting browsers) will control how the message
   *                     appears in the browser's console.
   *                     Optional, if absent will pass all arguments to console.log
   */
  function client (level) {
    if (!window.console || typeof window.console.log !== "function") {
      return;
    }

    if (_.contains(levels, level)) {
      var args = Array.prototype.slice.call(arguments, 1);
      var logFunction = console[level.toLowerCase()];

      if (level === levels.DEBUG && !debugEnabled) {
        return;
      }

      if (typeof logFunction !== "function") {
        logFunction = console.log;
      }

      logFunction.apply(console, args);
    } else {
      console.log.apply(console, arguments);
    }
  }

  /*
   * Send a log a message to the server.
   *
   * @param String level one of the log level constants.
   * @param String message the message text.
   */
  function server (level, message) {
    client(level, message);

    $.post("/ajax/client-log/", {
      level: level,
      message: message
    });
  }

  /*
   * Enables DEBUG level messages to be logged.
   */
  function enableDebug () {
    debugEnabled = true;
  }

  return _.extend({
    client: client,
    server: server,
    enableDebug: enableDebug
  }, levels);
});
