define(function (require) {
  const _ = require("underscore");

  function getDataLayers () {
    var dataLayers = [];
    var dataLayerId = 1;
    var dataLayerIdLimit = 3;

    while (true) { // eslint-disable-line no-constant-condition
      var dataLayerName = "dataLayer".concat(dataLayerId);

      if (window[dataLayerName]) {
        dataLayers.push(window[dataLayerName]);
      } else if (dataLayerId === dataLayerIdLimit) {
        break;
      }

      dataLayerId++;
    }

    return dataLayers;
  }

  function normalizeData (data) {
    var normalizedData = {};

    _.each(data, (value, key) => {
      var newValue = value;

      if (_.isObject(newValue) && !_.isArray(newValue)) {
        newValue = normalizeData(newValue);
      }

      if (
        (_.isObject(newValue) && _.isEmpty(newValue)) ||
        (!_.isBoolean(newValue) && !_.isNumber(newValue) && _.isEmpty(newValue))
      ) {
        normalizedData[key] = undefined;
      } else {
        normalizedData[key] = newValue;
      }
    });

    return normalizedData;
  }

  function track (data, dataLayer, done) {
    const dataLayerData = normalizeData(data);

    if (done && typeof done === "function") {
      dataLayerData.eventCallback = done;
      dataLayerData.eventTimeout = 2000;
    }

    if (dataLayer) {
      return dataLayer.push(dataLayerData);
    }

    const dataLayers = getDataLayers();

    _.each(dataLayers, (layer) => {
      layer.push(dataLayerData);
    });

    return dataLayers;
  }

  return {
    getDataLayers: getDataLayers,
    track: track
  };
});
