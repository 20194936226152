define(function (require) {
  var $ = require("jquery");
  var _ = require("underscore");
  var config = require("common/page-config");
  var dataLayer = require("common/analytics/dataLayer");

  var NO_TRACK_PAGES = ["places-page", "venue-menu-page", "offer-details-page"];

  function checkPageType (uri) {
    let hash = "";
    var uriHash = window.location.hash.split("?")[0].substr(1);

    if (uriHash === "checkout-complete-page") {
      hash = "order-summary-confirmation";
    } else if (!_.isEmpty(uriHash)) {
      hash = uriHash;
    } else if (_.isEmpty(uriHash) && uri === "/") {
      hash = "homepage";
    }

    return hash;
  }

  function trackMobilePage (uri) {
    var dataLayerData = {};
    var pageType = window.location.hash.split("?")[0].substr(1);
    if (uri && config.isMobile && !_.contains(NO_TRACK_PAGES, pageType)) {
      dataLayerData = {
        event: "virtualPageView",
        page: {
          path: uri,
          title: document.title,
          type: checkPageType(uri),
          isTreatwellBrandedWidget: !$("#widget-stylesheet").length
        },
        venue: JSON.parse(localStorage.getItem("venue"))
      };

      dataLayer.track(dataLayerData);
    }
  }

  /*
   * data object structure
   *
   * data.action (required)
   * data.category (required)
   * data.property
   * data.label
   *
   * @return {Boolean}
   */
  function trackEvent (data, callback) {
    if (!data.category) {
      return false;
    }

    // setting the datalayer options
    var dataLayerOptions = {
      event: "trackEvent",
      eventData: {
        action: data.action,
        category: data.category,
        property: data.property || undefined,
        label: data.label || undefined,
        value: (data.value || data.value === 0) ? data.value : undefined
      }
    };

    // check if there is a callback function and add it to the options object
    if (callback && typeof callback === "function") {
      // Privacy blockers (such as ublock) can stop tracking software from loading.
      // In which case the callback would never be called.
      // So if it isn"t called in a reasonable amount of time, call it ourselves.
      var timeout = setTimeout(function () {
        timeout = null;
        callback();
      }, 500);

      dataLayerOptions.eventCallback = function () {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
          callback();
        }
      };
    }

    // push into dataLayer
    dataLayer.track(dataLayerOptions);
    return true;
  }

  function getTrackEventData (targetNode) {
    var trackEventElement, trackProperty, trackLabel;

    if (targetNode.data("track-event-action")) {
      trackEventElement = targetNode;
    } else if (targetNode.parents("[data-track-event-action]").length > 0) {
      trackEventElement = $(targetNode.parents("[data-track-event-action]")[0]);
    }

    if (!trackEventElement) {
      return null;
    }

    var trackAction = trackEventElement.data("track-event-action");
    var trackCategory = trackEventElement.data("track-event-category");

    if (targetNode.data("track-event-property")) {
      trackProperty = targetNode.data("track-event-property");
    } else if (targetNode.parents("[data-track-event-property]").length > 0) {
      trackProperty = $(targetNode.parents("[data-track-event-property]")[0]).data("track-event-property");
    }

    if (targetNode.parents("[data-track-event-label]").length > 0) {
      trackLabel = $(targetNode.parents("[data-track-event-label]")[0]).data("track-event-label");
    }

    return {
      action: trackAction,
      category: trackCategory,
      property: trackProperty,
      label: "" + trackLabel
    };
  }

  function trackEventAsync (data) {
    return new Promise((resolve) => {
      trackEvent(data, resolve);
    });
  }

  return {
    trackEvent,
    getTrackEventData,
    trackMobilePage,
    trackEventAsync
  };
});
